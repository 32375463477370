/*
 *
 */

import React, { useEffect } from "react";

function ClockHands() {
  const hourHand = React.useRef(null),
    minuteHand = React.useRef(null),
    secondHand = React.useRef(null);

  function updateTime() {
    const date = new Date();

    // Calculate degrees for the clock hands
    let sec = date.getSeconds() + date.getMilliseconds() / 1000,
      secToDeg = (sec / 60) * 360,
      min = date.getMinutes() + sec / 60,
      minToDeg = (min / 60) * 360,
      hr = date.getHours() + min / 60,
      hrToDeg = (hr / 12) * 360;

    // Rotate the clock hands
    secondHand.current.style.transform = `translatey(max(-0.75vw, -0.75vh)) rotate(${secToDeg}deg)`;
    minuteHand.current.style.transform = `translatey(max(-0.75vw, -0.75vh)) rotate(${minToDeg}deg)`;
    hourHand.current.style.transform = `translatey(max(-0.75vw, -0.75vh)) rotate(${hrToDeg}deg)`;
  }

  useEffect(() => {
    // Update the hands' positions every section
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  });

  return (
    <div className="hands">
      <span className="hour" ref={hourHand}></span>
      <span className="minute" ref={minuteHand}></span>
      <span className="second" ref={secondHand}></span>
    </div>
  );
}

export default ClockHands;
