/*
 * @file
 * A wrapper for a Photo component
 */

import Photo from "./Photo.js";

export default function PhotoCard() {
  return (
    <div className="photo-card card">
      <Photo />
    </div>
  );
}
