/*
 *
 */

import ClockDigits from "./ClockDigits.js";
import ClockHands from "./ClockHands.js";

function Clock() {
  return (
    <div className="clock">
      <div className="dial">
        <ClockDigits />
        <ClockHands />
      </div>
    </div>
  );
}

export default Clock;
