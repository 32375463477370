/*
 * @file
 * Create the numbers on the clock dial
 */

function ClockDigit({ digit }) {
  return (
    <label style={{ "--i": digit }}>
      <span>{digit}</span>
    </label>
  );
}

function ClockDigits() {
  const digits = [];
  for (let i = 1; i <= 12; i++) {
    digits.push(<ClockDigit key={i} digit={i} />);
  }
  return <div className="clock-digits">{digits}</div>;
}

export default ClockDigits;
